// src/WebSocketService.js

class WebSocketService {
  constructor() {
    this.socket = new WebSocket('wss://locationsocket.msiserviceltd.com');

    this.socket.onopen = () => {
      console.log('WebSocket connection established.');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  sendMessage(message) {
    this.socket.send(message);
  }

  closeConnection() {
    this.socket.close();
  }
}

export default WebSocketService;

