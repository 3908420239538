<!-- src/App.vue -->
<template>
  <div id="app">
    <WebSocketChat />
  </div>
</template>

<script>
import WebSocketChat from './components/WebSocketChat.vue';

export default {
  name: 'App',
  components: {
    WebSocketChat
  }
};
</script>

<style>
/* Add your global styles here */
</style>

