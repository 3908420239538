<template>
  <div>
    <h1>Realtime Location</h1>
    <div v-for="(message, index) in latestMessages" :key="index">
      {{ message }}
    </div> <br>
  
   
    
  </div>
</template>

<script>
import WebSocketService from '../WebSocketService';

export default {
  data() {
    return {
      messages: [],
      newMessage: '',
      socketService: null
    };
  },
  computed: {
    latestMessages() {
      const latestMessagesMap = new Map();
      this.messages.forEach(message => {
        latestMessagesMap.set(message.email, message);
      });
      return Array.from(latestMessagesMap.values());
    }
  },
  created() {
    this.socketService = new WebSocketService();
    this.socketService.socket.onmessage = (event) => {
      // Convert Blob to readable text
      event.data.text().then((text) => {
        const message = JSON.parse(text);
        this.messages.push(message);
        console.log(message);
      });
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.socketService.sendMessage(this.newMessage);
        this.newMessage = '';
      }
    }
  },
  beforeDestroy() {
    this.socketService.closeConnection();
  }
};
</script>

<style scoped>
/* Add your component styles here */
</style>

